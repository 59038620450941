

.text_area {
    color: $dark_med_grey;
    font-family: $ff_2;
    & h1, & h2, & h3, & h4, & h5, & h6 {
        font-family: $ff_3;
        margin-bottom: $s_min;
        font-weight: 300;
    }
    & h1 {
        font-size: $fs_max*3;
    }
    & h2, & h3 {
        font-size: $fs_med*2.5;
    }
    & h4, & h5 {
        font-size: $fs_def*2;
    }
    & h6 {
        font-size: $fs_min*2;
    }
    & p {
        font-size: $fs_def;
    }
    & .text_purple {
        color: $purple;
    }
    & .text_dark_purple {
        color: $dark_purple;
    }
    & .text_bold {
        font-weight: 700;
    }
    & .alignleft {
        float: left;
        margin-right: $s_med;
    }
    & .alignright {
        float: right;
        margin-left: $s_med;
    }
}