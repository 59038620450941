// CSS3 Animation

@mixin keyframe ($animation_name) {
  @-webkit-keyframes #{$animation_name} {
      @content;
  }

  @-moz-keyframes #{$animation_name} {
      @content;
  }

  @-o-keyframes #{$animation_name} {
      @content;
  }

  @keyframes #{$animation_name} {
      @content;
  }
}

@mixin animation ($delay, $duration, $animation, $timing, $count, $direction: normal, $fillmode: forwards) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-timing-function: $timing;
  -webkit-animation-iteration-count: $count;
  -webkit-animation-direction: $direction;
  -webkit-animation-fill-mode: $fillmode;
 
  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-timing-function: $timing;
  -moz-animation-iteration-count: $count;
  -moz-animation-direction: $direction;
  -moz-animation-fill-mode: $fillmode;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function: $timing;
  animation-iteration-count: $count;
  animation-direction: $direction;
  animation-fill-mode: $fillmode;
}

// Transition Mixin

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// Box Shadow Mixin

@mixin box-shadow($top, $left, $blur, $radius, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $radius $color;
    -moz-box-shadow:inset $top $left $blur $radius $color;
    box-shadow:inset $top $left $blur $radius $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $radius $color;
    -moz-box-shadow: $top $left $blur $radius $color;
    box-shadow: $top $left $blur $radius $color;
  }
}

// Full-size Reset 
@mixin full-size-reset {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

// Pseudo Reset 
@mixin pseudo-reset {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

// Filter Mixing
@mixin filter($value) {
  -webkit-filter: $value;
     -moz-filter: $value;
       -o-filter: $value;
          filter: $value;
}

// Rounded Manuel
@mixin rounded {
  border-radius: .25rem!important;
}