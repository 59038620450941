// Header Top 

header {
    z-index: 20;
    position: relative;
    transform: translateZ(0) translate3d(0,0,0);
    &.fixed_menu {
        position: sticky;
        top: 0;
        background: $black; 
        z-index: 99;
        transform: translateY(-100%) translateZ(0) translate3d(0,0,0);
        @include transition(transform $tt_med ease);
        @include animation(0, $tt_med, fixedHeader, ease, 1);
    }
}

.mobile_trigger {
    display: none;
    color: $rgba_light_70; 
    background-color: transparent;
    border: 0;
    font-size: $fs_max*1.5;
    padding: 0;
    margin: 0;
    line-height: 0;
}

@include keyframe(fixedHeader) {
    0% {
        transform: translateY(-100%) translateZ(0) translate3d(0,0,0);
    }

    100% {
        transform: translateY(-50px) translateZ(0) translate3d(0,0,0);
    }
}

.header_top {
    max-height: 50px;
    padding: $s_mic_x2 $s_min;
    border-bottom: 1px solid $rgba_light_10;
    font-size: $fs_min;
    i {
        color: $rgba_light_25
    }
}

.lang_selector {
    font-family: $ff_3;

    li+li {
        margin-left: $s_min
    }

    li {
        &.active {
            opacity: 0.5;
            a {
                pointer-events: none;
                cursor: default;
                &:hover {
                    color: inherit;
                    
                }
            }
        }
    }

    a {
        font-weight: 600;
        color: $rgba_light_40;
        transition: $tt_min;

        &:hover {
            color: $purple;
        }
    }

    img {
        display: inline-block;
        position: relative;
        top: -1px;
    }

}

.header_top_info_list {
    li {
        a {
            @include transition(color $tt_min ease);
            color: $rgba_light_70;
            &:hover {
                color: $purple;
            }
        }
    }
    li + li {
        margin-left: $s_min;
    }
}

.header_top_right {
    form {
        position: relative;
        background-color: $rgba_light_20;
        margin-left: $s_min;
        padding: $s-mic $s_mic_x2;
        overflow: hidden;
        @include transition(background-color $tt_min ease);
        input[type='search']{
            height: 20px;
            border: 0;
            font-size: $fs_min;
            padding: 0 $s_mic;
            background-color: transparent;
            &::-ms-clear, &::-ms-reveal { 
                display: none; 
                width: 0; 
                height: 0; 
            }
            &::-webkit-search-decoration, 
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button, 
            &::-webkit-search-results-decoration {
                display: none;
            }
        }
        i {
            color: $rgba_light_25
        }
    }
}

// Header Bottom

.header_bottom {
    @include transition(top $tt_min ease);
    padding: $s_min 0;
    position: relative;
    .logo { 
        @include transition(all $tt_min ease);
        img {
            max-height: $s_max;
            display: block;
        }
        .logo_animation {
            position: relative;
            outline: 1px solid transparent;
            @include animation(0, 2s, turnAround, ease, infinite, alternate);
            transform: translateZ(0);
        }
        &:hover {
            opacity: 0.75;
            .logo_animation {
                animation-play-state: paused;
            }
        }
    }
}

@include keyframe(turnAround) {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(180deg);
    }
}

.main-menu {
    & > ul > .menu-item + .menu-item {
        margin-left: $s_med     
    }
    .menu-item {
        &.active {
            .nav-link {
                font-weight: bold;
                color: $purple;
                &:before {
                    content: none;
                }
            }
        }
        .nav-link {
            white-space: nowrap;
            color: $rgba_light_70;
            overflow: hidden;
            position: relative;
            font-family: $ff_3;
            font-size: $fs_min;
            display: block;
            padding: 0;
            &::before {
                content: '';
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $purple;
                @include transition(all $tt_min cubic-bezier(0.7, 0, 0.3, 1))
            }
            &:hover {
                color: $purple;
                &::before {
                    left: 100%;
                }
            }
        }
        &.menu-item-has-children {
            position: relative;
            & .sub-menu {
                @include box-shadow(0px, 15px, 40px, -5px, $rgba_dark_15);
                padding: $s_med;
                list-style-type: none;
                position: absolute;
                top: calc(100% + #{$s_med*2});
                left: 50%;
                background-color: $white;
                transform: translateX(-50%);
                opacity: 0;
                visibility: hidden;
                @include transition(all $tt_min ease);
                & li {
                    margin-bottom: $s_mic_x2;
                    & .nav-link {
                        color: $rgba_dark_75;
                        &:hover {
                            color: $purple;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-bottom: 10px solid $white;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    width: 100%;
                    height: $s_med;
                    background-color: transparent;
                }
            }
            &:hover {
                & .sub-menu {
                    opacity: 1;
                    visibility: visible;
                    top: calc(100% + #{$s_med});
                }
            }
            & span {
                cursor: pointer;
                position: absolute;
                font-size: $s_max;
                width: 2px;
                height: 16px;
                background-color: $rgba_light_70; 
                right: 10px;
                top: 15px;
                transform: translateX(-50%);
                display: block;
                @include transition(transform $tt_min ease);
                &::before {
                    content: '';
                    position: absolute;
                    right: calc(50% - 8px);
                    top: calc(50% - 1px);
                    height: 2px;
                    width: 16px;
                    background-color: $rgba_light_70; 
                }
                &::after {
                    @include pseudo-reset;
                    width: 40px;
                    left: -26px;
                    top: -12px;
                    height: 40px;
                    background-color: transparent;
                }
                &.active {
                    transform-origin: center;
                    transform: translateX(-50%) rotate(45deg);
                    background-color: $purple;
                    &::before {
                        background-color: $purple;
                    }
                }
                &:hover {
                    background-color: $purple;
                    &::before {
                        background-color: $purple;
                    }
                }
            }
        }
    }
}