.main_button {
    display: block;
    color: #FFF;
    width: fit-content;
    font-family: $ff_3;
    font-weight: bold;
    text-transform: uppercase;
    background: $dark_purple;
    background: -moz-linear-gradient(90deg, $purple 0%, $dark_purple 50%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(90deg, $purple 0%, $dark_purple 50%, rgba(0,0,0,1) 100%);
    background: linear-gradient(90deg, $purple 0%, $dark_purple 50%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#{$purple}",endColorstr="#{$dark_purple}",GradientType=1);
    background-size: 200% 100%;
    background-position: left center;
    padding: $s_mic_x2 $s_med;
    @include transition(all $tt_min ease);
    top: 0;
    position: relative;
    letter-spacing: 1.05px;
    @include box-shadow(0px, 5px, 7.5px, 0, $rgba_dark_35);
    &:before {
        content :'';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 0;
    }
    &:hover {
        color: #FFF;
        background-position: right center;
        top: -$s_mic;
        @include box-shadow(0px, 10px, 20px, 0, $rgba_dark_25);
        &:before {
            height: $s_mic;
        }
    }
}

input[type="submit"].main_button, button.main_button { 
    border: 0;
    cursor: pointer;
}
