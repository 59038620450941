@media (max-width: 576px) {
    .temp_layout {
        &.rounded, &.rounded-top, &.rounded-bottom {
            border-radius: 0!important;
        }
    }

    .footer_copyright {
        text-align: center;
        & .penta_logo {
            justify-content: center!important;
            padding-top: $s_med;
        }
    }

    .footer_content {
        padding: $s_max!important;
    }

}