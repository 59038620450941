@media (max-width: 768px) {
    body {
        overflow-x: hidden;
    }
    .promo_animation_image {
        display: none;
    }
    .promo_text {
        align-items: center;
        text-align: center;
    }
    .temp_six_carousel_text {
        & p {
            font-size: $fs_med!important;
            padding: $s_max!important;
        }
    }
    .temp_six_carousel_caption {
        font-size: $fs_min!important;
    }
}