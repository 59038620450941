@media (max-width: 1200px) {
    .mobile_menu {
        @include box-shadow(0px, 10px, 50px, -20px, $rgba_dark_15);
        & .header_top_info_list {
            & li:not(:first-child) {
                display: none;
            }
            & + form {
                display: none!important;
            }
        }
        & .mobile_trigger {
            display: block;
        }
        &.fixed_menu {
            & .main-menu {
                max-height: calc(100vh - 90px);
            }
        }
        & .main-menu {
            display: none;
            z-index: 99;
            transform: translateZ(0) translate3d(0,0,0);
            overflow-y: auto;
            position: absolute;
            left: 0;
            width: 100%;
            top: 100%;
            background-color: $dark_black_grey;
            max-height: calc(100vh - (90px + 42px));
            & ul {
                padding: $s_med;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start!important;
                & li + li {
                    border-top: 1px solid $rgba_light_05;
                    margin-top: $s_mic_x2;
                    padding-top: $s_mic_x2;
                }
                & .menu-item {
                    width: 100%;
                    margin-left: 0;
                    & .nav-link {
                        font-size: $fs_med;
                        &::before {
                            content: none;
                        }
                    }
                }
            }
            &.active {
                display: block;
            }
        }
        & .sub-menu {
            display: none;
            margin: $s_mic_x2 0;
            padding: $s_med!important;;
            transition: all 0s ease 0s!important;
            box-shadow: none!important;
            position: relative!important;
            top: 0!important;
            left: 0!important;
            background-color: $dark_grey!important;
            transform: none!important;
            opacity: 1!important;
            visibility: visible!important;
            & li {
                margin-bottom: $s_mic_x2;
                & .nav-link {
                    color: $rgba_light_70!important;
                    &:hover {
                        color: $purple!important;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &::before, &::after {
                content: none!important;
            }
            &.active {
                display: block;
            }
        }
    }

    @include keyframe(fixedHeader) {
        0% {
            transform: translateY(-100%);
        }
    
        100% {
            transform: translateY(-42px);
        }
    }
}