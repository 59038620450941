.promo {
    min-height: 750px;
    position: relative;
    top: -150px;
    z-index: 0;
    transform: translateZ(0) translate3d(0,0,0);
    .promo_background {
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        @include full-size-reset;
        z-index: 1;
        transform: translateZ(0) translate3d(0,0,0);
        .promo_stars, .promo_twinkling, .promo_space_color, .promo_background_image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
        .promo_stars {
            background: #000 url(../media/img/stars.png) repeat top center;
            z-index: 2;
            transform: translateZ(0) translate3d(0,0,0);
        }        
        .promo_twinkling {
            background: transparent url(../media/img/twinkling.png) repeat top center;
            z-index: 3;
            transform: translateZ(0) translate3d(0,0,0);
            @include animation(0, 200s, move-twink-back, linear, infinite);
        }
        .promo_space_color {
            background: rgb(69,75,123);
            background: -moz-radial-gradient(circle, rgba(69,75,123,1) 0%, rgba(16,24,58,1) 35%, rgba(0,0,0,1) 85%);
            background: -webkit-radial-gradient(circle, rgba(69,75,123,1) 0%, rgba(16,24,58,1) 35%, rgba(0,0,0,1) 85%);
            background: radial-gradient(circle, rgba(69,75,123,1) 0%, rgba(16,24,58,1) 35%, rgba(0,0,0,1) 85%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#454b7b",endColorstr="#000000",GradientType=1);
            z-index: 4;
            opacity: 0.75;
            transform: translateZ(0) translate3d(0,0,0);
            &::before {
                @include pseudo-reset;
                background: rgb(184,190,238);
                background: -moz-radial-gradient(circle, rgba(184,190,238,1) 0%, rgba(70,87,158,1) 25%, rgba(0,0,0,1) 80%);
                background: -webkit-radial-gradient(circle, rgba(184,190,238,1) 0%, rgba(70,87,158,1) 25%, rgba(0,0,0,1) 80%);
                background: radial-gradient(circle, rgba(184,190,238,1) 0%, rgba(70,87,158,1) 25%, rgba(0,0,0,1) 80%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b8beee",endColorstr="#000000",GradientType=1);
                opacity: 0;
                @include animation(0, 10s, shineSpace, ease-out, infinite, alternate);
            }
        }
        .promo_single_image { 
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include animation(0, 40s, promo-image-scale, ease-out, infinite, alternate)
        }
        .promo_single_video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    } 
    &::before {
        z-index: 5; 
        transform: translateZ(0) translate3d(0,0,0);
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 75%;
        background: rgba(0,0,0,0.5);
        background: -moz-linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 85%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 85%, rgba(0,0,0,1) 100%);
        background: linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 85%, rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
    &::after {
        z-index: 6;
        transform: translateZ(0) translate3d(0,0,0);
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;
        background: rgba(0,0,0,0.5);
        background: -moz-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 80%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 80%, rgba(0,0,0,1) 100%);
        background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.8) 80%, rgba(0,0,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
}

@include keyframe(promo-image-scale) {
    0% {
        transform: scale(1) translateZ(0) translate3d(0,0,0);
    }

    100% {
        transform: scale(1.2) translateZ(0) translate3d(0,0,0);
    }
}

@include keyframe(move-twink-back) {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -10000px 5000px;
    }
}


@include keyframe(shineSpace) {
    0%, 10% {
        opacity: 0
    }

    15%, 85% {
        opacity: .9
    }

    100% {
        opacity: 0
    }
}

@include keyframe(moveSpaceMan) {
    100% {
        transform: translateY(-50%) translateZ(0) translate3d(0,0,0);
    }
}

.glow {
    @include animation(0, 3s, textGlow, ease-in-out, infinite, alternate);
}

@include keyframe(textGlow) {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px $dark_purple, 0 0 40px $dark_purple, 0 0 50px $dark_purple, 0 0 60px $dark_purple, 0 0 70px $dark_purple;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px $purple, 0 0 40px $purple, 0 0 50px $purple, 0 0 60px $purple, 0 0 70px $purple, 0 0 80px $purple;
    }
}

.promo_content {
    z-index: 7;
    left: 50%;
    transform: translateX(-50%) translateZ(0) translate3d(0,0,0);
    position: absolute;
    min-height: 400px;
    margin-top: 200px;
    .promo_text {
      font-family: $ff_3;
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 400px;
      h2 {
          color: $white;
          font-size: $fs_max*3;
          font-weight: 700;
      }
      p {
          color: $purple;
          font-size: $fs_max*1.5;
          font-weight: 300;
      }
    }
    .space_man {
        z-index: 9;
        position: absolute;
        background: url(../media/img/spaceman.png) no-repeat center center;
        background-size: contain;
        height: 100%;
        padding-left: 100%;
        top: 55%;
        right: 0;
        transform: translateY(-55%) translateZ(0) translate3d(0,0,0);
        filter: drop-shadow(-30px 15px 200px rgba(0, 0, 0, 1));
        @include animation(0, 3s, moveSpaceMan, ease-out, infinite, alternate)
    }
}

.promo_scroll {
    z-index: 10;
    position: absolute;
    top: calc(100% - 100px);
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0) translate3d(0,0,0);
}

@media (max-width: 991px) {
    .promo_scroll {
        display: none;
    }
}

.promo_scroll::before {
    z-index: 11;
    cursor: pointer;
    content: '';
    position: absolute;
    left: 0;
    top: 15px;
    width: 40px;
    height: 100px;
    transform: translateX(-50%) translateY(-50%) translateZ(0) translate3d(0,0,0);
    opacity: 0;
}

.promo_scroll span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg) translateZ(0) translate3d(0,0,0);
    margin: -10px;
    @include animation(0, 2s, scrollButton, ease, infinite, alternate);
    transition: .5s;
}

.promo_scroll span:nth-child(2) {
    animation-delay: -0.2s;
}

.promo_scroll span:nth-child(3) {
    animation-delay: -0.4s;
}

@include keyframe(scrollButton) {
    0%{
        opacity: 0;
        transform: rotate(45deg) translateX(-20px) translateY(-20px) translateZ(0) translate3d(0,0,0);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translateX(-20px) translateY(-20px) translateZ(0) translate3d(0,0,0);
    }
} 