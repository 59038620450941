// Text Color
$white: #FFF;
$soft_grey: #f7f7f7;
$soft_med_grey: #e0e0e0;
$med_grey: #999;
$reg_grey: #666;
$dark_med_grey: #333;
$dark_grey: #1d1d1d;
$dark_black_grey: #141414;
$black: #000;

$purple: #8c52c1;
$dark_purple: #553771;

$rgba_dark_05: rgba(0,0,0,0.05);
$rgba_dark_10: rgba(0,0,0,0.1);
$rgba_dark_15: rgba(0,0,0,0.15);
$rgba_dark_20: rgba(0,0,0,0.2);
$rgba_dark_25: rgba(0,0,0,0.25);
$rgba_dark_30: rgba(0,0,0,0.3);
$rgba_dark_35: rgba(0,0,0,0.35);
$rgba_dark_40: rgba(0,0,0,0.4);
$rgba_dark_45: rgba(0,0,0,0.45);
$rgba_dark_50: rgba(0,0,0,0.5);
$rgba_dark_55: rgba(0,0,0,0.55);
$rgba_dark_60: rgba(0,0,0,0.6);
$rgba_dark_65: rgba(0,0,0,0.65);
$rgba_dark_70: rgba(0,0,0,0.7);
$rgba_dark_75: rgba(0,0,0,0.75);
$rgba_dark_80: rgba(0,0,0,0.8);
$rgba_dark_85: rgba(0,0,0,0.85);
$rgba_dark_90: rgba(0,0,0,0.9);
$rgba_dark_95: rgba(0,0,0,0.95);

$rgba_light_05: rgba(255,255,255,0.05);
$rgba_light_10: rgba(255,255,255,0.1);
$rgba_light_15: rgba(255,255,255,0.15);
$rgba_light_20: rgba(255,255,255,0.2);
$rgba_light_25: rgba(255,255,255,0.25);
$rgba_light_30: rgba(255,255,255,0.3);
$rgba_light_35: rgba(255,255,255,0.35);
$rgba_light_40: rgba(255,255,255,0.4);
$rgba_light_45: rgba(255,255,255,0.45);
$rgba_light_50: rgba(255,255,255,0.5);
$rgba_light_55: rgba(255,255,255,0.55);
$rgba_light_60: rgba(255,255,255,0.6);
$rgba_light_65: rgba(255,255,255,0.65);
$rgba_light_70: rgba(255,255,255,0.7);
$rgba_light_75: rgba(255,255,255,0.75);
$rgba_light_80: rgba(255,255,255,0.8);
$rgba_light_85: rgba(255,255,255,0.85);
$rgba_light_90: rgba(255,255,255,0.9);
$rgba_light_95: rgba(255,255,255,0.95);

// Space Sizes
$s_mic: 5px;
$s_mic_x2: 10px;
$s_min: 15px;
$s_med: 30px;
$s_max: 60px;
$s_ult: 90px;

// Font Size
$fs_def: 1rem;
$fs_min: 0.9rem;
$fs_med: 1.15rem;
$fs_med_two: 1.20rem;
$fs_max: 1.25rem;
$fs_max_two: 1.3rem;
$fs_ultra: 1.4rem;

// Font Family
$ff_2: 'Kumbh Sans', sans-serif;
$ff_3: 'Barlow', sans-serif;

// Transition Time
$tt_min: .3s;
$tt_short: .6s;
$tt_med: .9s;
$tt_long: 1.2s;
$tt_max: 1.5s;

// Border Radius
$br_min: 5px;
$br_med: 15px;
$br_max: 25px;
$br_ult: 50px;
$br_round: 50%;