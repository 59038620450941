.main {
    position: relative;
    background-color: $soft_grey;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.first_temp {
    top: -150px!important;
}

.first_temp + div {
    margin-top: -50px;
}

.temp_layout {
    background-color: #FFF;
    width: 100%;
    position: relative;
    @include box-shadow(0px, 10px, 50px, -20px, $rgba_dark_15);
}

.wide_temp_layout {
    background-color: #FFF;
    width: 100%;
    position: relative;
    @include box-shadow(0px, 10px, 50px, -20px, $rgba_dark_15);
}

// Template One
.temp_one {
    padding: $s_max;
}

// Template Two
.temp_two {
    position: relative;
    & > .full_page_image {
        & > img {
            width: 100%;
            height: auto;
            object-fit: cover;
            min-height: 300px;
        }
    } 
}

// Template Three
.temp_three {
    overflow: hidden;
    background-color: $black;
    & .temp_three_image {
        & > p {
            z-index: 12;
            transform: translateZ(0) translate3d(0,0,0);
            @include full-size-reset;
            background-color: $rgba_dark_40;
            color: #FFF;
            font-size: $fs_max*1.5;
            font-family: $ff_3;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & > a {
            z-index: 13;
            transform: translateZ(0) translate3d(0,0,0);
            @include full-size-reset;
        }
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include transition(transform $tt_med ease);
        }
        &::before, &::after {
            @include pseudo-reset;
            border-radius: 50%;
            background-color: $rgba_dark_50;
            top: 100%;
            left: 50%;
            transform: translateX(-50%) translateZ(0) translate3d(0,0,0);
            z-index: 11;
        }
        &::before {
            width: 150%;
            padding-top: 150%;
            @include transition(transform $tt_max ease, border-radius $tt_max*1.5 ease);
        }
        &::after {
            width: 175%;
            padding-top: 175%;
            @include transition(transform $tt_max ease, border-radius $tt_max*1.5 ease);
            transition-delay: $tt_short;
        }
        &:hover {
            & > img {
                transform: scale(1.1) translateZ(0) translate3d(0,0,0);
            }  
            &::before {
                transform: translateX(-50%) translateY(-60%) translateZ(0) translate3d(0,0,0);
                border-radius: 0;
            }
            &::after {
                transform: translate(-50%, -60%) translateZ(0) translate3d(0,0,0);
                border-radius: 0;                
            }
        }
    }
}

// Template Four
.temp_four {
    overflow: hidden;
    & .temp_four_list {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $dark_med_grey;
        padding: $s_max $s_med;
        color: #FFF;
        font-size: $fs_min*1.4;
        & li {
            list-style-type: none;
            margin-bottom: $s_med;
            position: relative;
            &:last-child {
                margin: 0;
            }
            &::before {
                content: '';
                display: inline-block;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MzYgNDM2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yOTguNDYsMTM3LjI0YTI2LjEsMjYuMSwwLDAsMC0xOC41Nyw3LjY5bC05MC40NSw5MC40Ni0zMy4zMy0zMy4zNEEyNi4yNSwyNi4yNSwwLDEsMCwxMTksMjM5LjE3bDUxLjksNTEuOWEyNi4yNCwyNi4yNCwwLDAsMCwzNy4xMiwwbDEwOS0xMDlhMjYuMjUsMjYuMjUsMCwwLDAtMTguNTUtNDQuODFaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMzcyLjE5LDYzLjgyQTIxOC4wNSwyMTguMDUsMCwwLDAsNjMuODEsMzcyLjE4LDIxOC4wNSwyMTguMDUsMCwwLDAsMzcyLjE5LDYzLjgyWk0yMTgsMzgzLjVjLTkxLjI2LDAtMTY1LjUtNzQuMjQtMTY1LjUtMTY1LjVTMTI2Ljc0LDUyLjUsMjE4LDUyLjUsMzgzLjUsMTI2Ljc0LDM4My41LDIxOCwzMDkuMjYsMzgzLjUsMjE4LDM4My41WiIvPjwvZz48L2c+PC9zdmc+);
                width: 20px;
                height: 20px;
                position: relative;
                top: 3px;
                left: -10px;
                @include filter(invert(42%) sepia(37%) saturate(1179%) hue-rotate(231deg) brightness(84%) contrast(88%));
            }
        }
    }
}

// Template Five
.temp_five {
    padding: $s_max $s_max $s_med $s_max;
    &::before {
        @include pseudo-reset;
        height: 225px;
        background: $dark_purple;
        background: -moz-linear-gradient(90deg, $purple 0%, $dark_purple 100%);
        background: -webkit-linear-gradient(90deg, $purple 0%, $dark_purple 100%);
        background: linear-gradient(90deg, $purple 0%, $dark_purple 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#{$purple}",endColorstr="#{$dark_purple}",GradientType=1);
    }
    & .temp_five_carousel {
        & img {
            width: 100%;
            height: 250px;
            object-fit: cover;
        }
        & .temp_five_carousel_item {
            @include box-shadow(0px, 10px, 50px, -20px, $rgba_dark_15);
            margin-bottom: $s_med*1.5;
            overflow: hidden;
            & .temp_five_carousel_title {
                padding: $s_min*1.5;
                width: calc(100% - 60px);
                background-color: $soft_grey;
                height: 84px;
                & p {
                    color: $dark_med_grey;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; 
                    -webkit-box-orient: vertical;
                    font-weight: 700;
                    font-size: $fs_min*1.2;
                    @include transition(opacity $tt_min ease);
                }
            }
            & .temp_five_carousel_date {
                background: $dark_purple;
                background: -moz-linear-gradient(45deg, $purple 0%, $dark_purple 100%);
                background: -webkit-linear-gradient(45deg, $purple 0%, $dark_purple 100%);
                background: linear-gradient(45deg, $purple 0%, $dark_purple 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#{$purple}",endColorstr="#{$dark_purple}",GradientType=1);
                width: 60px;
                position: absolute;
                top: 250px;
                right: 0;
                font-family: $ff_3;
                font-weight: 500;
                text-align: center;
                padding: $s_min;
                display: flex;
                color: $white;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                max-height: 84px;
                overflow: hidden;
                @include transition(width $tt_med ease);
                & p {
                    @include transition(opacity $tt_min ease);
                    & span {
                        display: block;
                        font-weight: 800;
                        font-size: $fs_max*1.3;
                        line-height: 30px;
                    }
                }
            }
            & .temp_five_carousel_text {
                padding: $s_med $s_min*1.5;
                width: 100%;
                max-height: 125px;
                & p {
                    color: $dark_med_grey;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; 
                    -webkit-box-orient: vertical;
                }
            }
            & .temp_five_carousel_button {
                @include full-size-reset;
                @include transition(opacity $tt_min*1.5 ease);
                opacity: 0;
                visibility: 0;
                text-align: center;
                top: 250px;
                color: #FFF;
                font-weight: 700;
                font-family: $ff_3;
                font-size: $fs_med;
                padding: $s_med 0;
                & i {
                    margin-left: $s_min;
                    opacity: 0.75;
                }
            }
            &:hover {
                & .temp_five_carousel_title {
                    & p {
                        opacity: 0;
                    }
                }
                & .temp_five_carousel_date {
                    width: 100%;
                    & p {
                        opacity: 0;
                    }
                }
                & .temp_five_carousel_button {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: $tt_med;
                }
            }
        }
    }
} 

// Template Six
.temp_six {
    padding: $s_max;
    box-shadow: none;
    &::before {
        @include pseudo-reset;
        height: 225px;
        background: $dark_purple;
        background: -moz-linear-gradient(90deg, $purple 0%, $dark_purple 100%);
        background: -webkit-linear-gradient(90deg, $purple 0%, $dark_purple 100%);
        background: linear-gradient(90deg, $purple 0%, $dark_purple 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#{$purple}",endColorstr="#{$dark_purple}",GradientType=1);
    }
    & .temp_six_carousel {
        & .temp_six_carousel_item {
            overflow: hidden;
            & .temp_six_carousel_text {
                @include box-shadow(0px, 10px, 50px, -20px, $rgba_dark_15);
                position: relative;
                & p {
                    background-color: $white;
                    padding: $s_max $s_max*1.5;
                    position: relative;
                    font-style: italic;
                    font-size: $fs_med*1.2;
                    overflow: hidden;
                    &::before, &::after {
                        font-family: "Font Awesome 5 Free"; 
                        font-weight: 900; 
                        position: absolute;
                        font-size: $s_max;
                        opacity: .1;
                    }
                    &::before {                   
                        content: "\f10d";
                        left: $s_min;
                        top: $s_min;
                        transform: translateY(-50%);
                    }
                    &::after {
                        content: "\f10e";
                        right: $s_min*2;
                        bottom: $s_min;
                        transform: translateY(50%);
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    border: $s_min solid $white;
                    border-color: transparent transparent $white $white;
                    transform-origin: 0 0;
                    transform: rotate(-45deg);
                    top: 100%;
                    left: $s_med*1.5;
                    @include box-shadow(-30px, 30px, 50px, 0, $rgba_dark_15);
                }
            }
            & .temp_six_carousel_name {
                padding: 0 $s_med;
                font-family: $ff_2;
                font-size: $fs_max;
                color: $dark_med_grey;
                color: $dark_purple;
                font-weight: 700;
            }
            & .temp_six_carousel_caption {
                padding: 0 $s_med;
                font-family: $ff_2;
                font-size: $fs_max;
                color: $med_grey;
                font-weight: 300;
            }
        }
    }
} 

// Template Seven
.temp_seven {
    overflow: hidden;
    background-color: $black;
    & .temp_seven_image {
        padding-top: 100%;
        & > p {
            z-index: 12;
            transform: translateZ(0) translate3d(0,0,0);
            @include full-size-reset;
            background-color: $rgba_dark_60;
            color: #FFF;
            font-size: $fs_min*1.5;
            font-family: $ff_3;
            display: flex;
            justify-content: center;
            align-items: center;
            @include transition(opacity $tt_min ease);
        }
        & > a {
            z-index: 13;
            transform: translateZ(0) translate3d(0,0,0);
            @include full-size-reset;
        }
        & > img {
            width: 100%;
            height: 100%;
            min-height: 250px;
            object-fit: cover;
            @include transition(transform $tt_med ease);
        }
        &:hover {
           & p {
               opacity: 0;
           }
           & > img {
            transform: scale(1.1) translateZ(0) translate3d(0,0,0);;
        }  
        }
    }
}


// Template Eight
.temp_eight {
    position: relative;
    & > .full_page_image {
        & > img {
            width: 100%;
            height: auto;
            object-fit: cover;
            min-height: 300px;
        }
    } 
}

// Template Nine
.temp_nine {
    position: relative;
    padding: $s_max;
    .temp_nine_accordion {
        display: block;
        @include box-shadow(0px, 10px, 50px, -20px, $rgba_dark_15);
        padding: $s_med;
        margin-bottom: $s_med;
        & img {
            @include rounded;
        }
        & .temp_nine_accordion_title {
            cursor: pointer;
            font-family: $ff_3;
            font-size: $fs_max*1.5;
            position: relative;
            padding-left: $s_med*1.5;
            &::after, &::before {
                content: '';
                position: absolute;
                background-color: $purple;
                transform-origin: center;
                @include transition(transform $tt_min ease);
            }
            &::after {
                width: 2px;
                height: 16px;
                left: 15px;
                top: calc(50% - 7px);
            }
            &::before {
                left: 8px;
                top: 50%;
                height: 2px;
                width: 16px;
            } 
            &.active {
                &::after, &::before {
                    transform: rotate(45deg);
                }
            }
        }
        & .temp_nine_accordion_content {
            overflow: hidden;
            max-height: 0;
            padding-left: $s_med;
            padding-right: $s_med;
            @include transition(margin $tt_min ease, max-height $tt_max ease, padding-top $tt_med ease, padding-bottom $tt_med ease);
            &.active {
                border-top: 1px solid $rgba_dark_05;
                margin-top: $s_med;
                max-height: 999px;
                padding-top: $s_med*1.2;
                padding-bottom: $s_med;
            }
        }  
    }
    & div {
        & div:last-child {
            margin-bottom: 0;
        }
    }
}

// Template Ten 
.temp_ten {
    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    & .temp_ten_text {
        padding: $s_max;
    }
}

// Template Eleven
.temp_eleven {
    padding: $s_max;
    & .temp_eleven_title {
        color: $purple;
        text-align: center;
    }
    & .temp_eleven_list {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: $fs_min*1.4;
        & li {
            list-style-type: none;
            margin-bottom: $s_med/2*1.5;
            position: relative;
            &:last-child {
                margin: 0;
            }
            &::before {
                content: '';
                display: inline-block;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MzYgNDM2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yOTguNDYsMTM3LjI0YTI2LjEsMjYuMSwwLDAsMC0xOC41Nyw3LjY5bC05MC40NSw5MC40Ni0zMy4zMy0zMy4zNEEyNi4yNSwyNi4yNSwwLDEsMCwxMTksMjM5LjE3bDUxLjksNTEuOWEyNi4yNCwyNi4yNCwwLDAsMCwzNy4xMiwwbDEwOS0xMDlhMjYuMjUsMjYuMjUsMCwwLDAtMTguNTUtNDQuODFaIi8+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNMzcyLjE5LDYzLjgyQTIxOC4wNSwyMTguMDUsMCwwLDAsNjMuODEsMzcyLjE4LDIxOC4wNSwyMTguMDUsMCwwLDAsMzcyLjE5LDYzLjgyWk0yMTgsMzgzLjVjLTkxLjI2LDAtMTY1LjUtNzQuMjQtMTY1LjUtMTY1LjVTMTI2Ljc0LDUyLjUsMjE4LDUyLjUsMzgzLjUsMTI2Ljc0LDM4My41LDIxOCwzMDkuMjYsMzgzLjUsMjE4LDM4My41WiIvPjwvZz48L2c+PC9zdmc+);
                width: 20px;
                height: 20px;
                position: relative;
                top: 3px;
                left: -10px;
                @include filter(invert(42%) sepia(37%) saturate(1179%) hue-rotate(231deg) brightness(84%) contrast(88%));
            }
        }
    }
}

// Template Twelve

.temp_twelve {
    padding: $s_max;  
    & form {
        & input, textarea {
            width: 100%;
            margin-bottom: $s_min;
            padding: $s_med/1.5;
            @include rounded;
            border: 1px solid $rgba_dark_15;
            &::placeholder {
                color: $rgba_dark_40;
            }
        }
    }
    & .temp_twelve_map {
        & iframe {
            width: 100%;
            height: 100%;
        }
    }
}