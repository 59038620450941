.reference {
    background: $dark_purple;
    background: -moz-linear-gradient(90deg, $purple 0%, $dark_purple 100%);
    background: -webkit-linear-gradient(90deg, $purple 0%, $dark_purple 100%);
    background: linear-gradient(90deg, $purple 0%, $dark_purple 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#{$purple}",endColorstr="#{$dark_purple}",GradientType=1);
    & .reference_carousel {
        padding: $s_max 0;
        & .owl-stage {
            display: flex;
            align-items: center;
        }
        & .reference_logo {
            cursor: grab;
            opacity: 0.6;
            @include transition(opacity $tt_min ease);
            &:hover {
                opacity: 1;
            }
        }
    }
} 

.social_media {
    background-color: $soft_grey;
    padding: $s_med 0;
    & .social_media_list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & p {
            font-family: $ff_3;
            font-weight: 500;
            color: $reg_grey;
            margin-right: $s_med;
            font-size: $fs_med;
        }
        & a {
            font-size: $fs_med_two*2;
            line-height: 0;
            color: $med_grey;
            @include transition(color $tt_min ease);
            & + a {
                margin-left: $s_mic_x2;
            }
        }
        & .facebook {
            &:hover {
                color: #3B5998;
            }
        }
        & .twitter {
            &:hover {
                color: #1DA1F2;
            }
        } 
        & .instagram {
            &:hover {
                color: #C13584;
            }
        }
        & .linkedin {
            &:hover {
                color: #0072B1 ;
            }
        }
    }
}

.footer_segment {
    min-height: 500px;
    position: relative;
    & .footer_background {
        @include full-size-reset;
        z-index: 0;
        & .footer_stars, & .footer_twinkling, & .footer_space_color {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
        & .footer_stars {
            background: #000 url(../media/img/stars.png) repeat top center;
            z-index: 1;
        }        
        & .footer_twinkling {
            background: transparent url(../media/img/twinkling.png) repeat top center;
            z-index: 2;
            @include animation(0, 200s, move-twink-back, linear, infinite)
        }
        & .footer_space_color {
            background: rgb(69,75,123);
            background: -moz-radial-gradient(circle, rgba(69,75,123,1) 0%, rgba(16,24,58,1) 35%, rgba(0,0,0,1) 85%);
            background: -webkit-radial-gradient(circle, rgba(69,75,123,1) 0%, rgba(16,24,58,1) 35%, rgba(0,0,0,1) 85%);
            background: radial-gradient(circle, rgba(69,75,123,1) 0%, rgba(16,24,58,1) 35%, rgba(0,0,0,1) 85%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#454b7b",endColorstr="#000000",GradientType=1);
            z-index: 3;
            opacity: 0.75;
            width: 100%;
        }
    }
    & .footer_content {
        color: $rgba_light_70;
        padding: $s_ult 0 $s_max 0;
        & .footer_logo {
            & img {
                width: 100%;
            }
        }
        & .footer_desc {
            font-size: $fs_med;
            font-family: $ff_3;
            font-weight: 300;
            & strong {
                font-weight: 600;
            }
        }
    }
    & .footer_list_title {
        font-family: $ff_3;
        font-size: $fs_ultra*1.2;
        color: $purple;
        font-weight: 600;
    }
    & .footer_list {
        list-style-type: none;
        padding-left: 0;
        margin-top: $s_med;
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        & li {
            margin-bottom: $s_mic_x2;
            & a {
                width: fit-content;
                color: $rgba_light_70;
                position: relative;
                overflow: hidden;
                display: block;
                &::before {
                    content: '\f0da';
                    font-family: "Font Awesome 5 Free"; 
                    font-weight: 900; 
                    display: inline-block;
                    margin-right: $s_mic_x2;
                    color: $purple;
                }
                &::after {
                    content: '';
                    position: absolute;
                    left: -100%;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $purple;
                    @include transition(all $tt_min cubic-bezier(0.7, 0, 0.3, 1))
                }
                &:hover {
                    color: $purple;
                    &::after {
                        left: 100%;
                    }
                }
            }
        }
    }
    & .footer_info_list {
        list-style-type: none;
        padding-left: 0;
        margin-top: $s_med;
        & li {
            margin-bottom: $s_mic_x2;
            color: $rgba_light_70;
            & i {
                display: inline-block;
                margin-right: $s_mic_x2;
            }
            & a {
                width: fit-content;
                color: $rgba_light_70;
                position: relative;
                overflow: hidden;
                display: block;
                &::after {
                    content: '';
                    position: absolute;
                    left: -100%;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $purple;
                    @include transition(all $tt_min cubic-bezier(0.7, 0, 0.3, 1))
                }
                &:hover {
                    color: $purple;
                    &::after {
                        left: 100%;
                    }
                }
            }
        }
    }
    & .footer_copyright {
        position: relative;
        z-index: 4;
        padding: $s-med 0;
        border-top: 1px solid $rgba_light_10;
        color: $rgba_light_50;
        font-family: $ff_3;
    }
}

.penta-logo {
    position:relative;
    height:23px;
    width:130px;
    background-repeat:no-repeat;
    background-size:contain;
    display: inline-block;
    & .penta-logo-y {        
        @include animation(0, 2s, ydondur, linear, infinite, alternate);
        transform-style:preserve-3d;
        background-repeat: no-repeat;
        width: 12px;
        height: 12px;
        position: absolute;
        top: 12px;
        left: 9px;
        background-size: contain;
    }
}

@include keyframe(ydondur) {
    0% {
        transform: rotateY(0deg) rotateZ(0) rotate3d(0,0,0);
    }
    50% {
        transform: rotateY(0deg) rotateZ(0) rotate3d(0,0,0);
    }
    100% {
        transform: rotateY(-360deg) rotateZ(0) rotate3d(0,0,0);
    }
}